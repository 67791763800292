import { IBreedingLocation } from './breedingLocation';
import { IDevelopmentalStage } from './developmentalStage';
import { IFishType } from './fishtype';
import { IPopulatedWaterbody } from './waterbody';

export interface IFacility {
  id: number;
  activeLayers: number[];
  name: string;
  nameEn: string;
  nameDe: string;
  nameIt: string;
  nameFr: string;
  location: IBreedingLocation;
  type: FacilityType;
  layersCount: number;
  facilityStages: FacilityStage[];
  children?: IFacility[];
}

export type FacilityNameKey = 'nameDe' | 'nameEn' | 'nameFr' | 'nameIt';

export interface IMedication {
  id: number;
  name: string;
  procedure: string;
  dose: number;
  remarks: string;
  dateApplied: string;
  unit: string | null;
}

export interface ITreatment {
  id: number;
  name: string;
  procedure: string;
  dose: number;
  remarks: string;
  dateApplied: string;
  unit: string | null;
}

export interface FacilityStage {
  id: number;
  type: FacilityType;
  assignedWaterbody: IPopulatedWaterbody;
  createdAt: string;
  inspectorId: number[];
  batch: IBatch;
  inventory: { id: number; createdAt: string } | null;
  isCurrent: boolean;
  remarks: string;
  layers: number[];
  number: number;
  numberHistory: { id: number; value: number; type: string; createdAt: string }[];
  weight: { weight: number; number: number };
  weightHistory: { id: number; value: { number: number; weight: number; date: string } }[];
  medications: IMedication[];
  treatments: ITreatment[];
  developmentalStage: IDevelopmentalStage | null;
  developmentalStageHistory: { id: number; value: IDevelopmentalStage; type: string; createdAt: string }[];
}

export interface IBatch {
  id: number;
  waterbody: IPopulatedWaterbody;
  fishtype: IFishType;
  number: number;
  createdAt: string | null;
  year: number;
  developmentalStage: IDevelopmentalStage;
  breedingLocation: IBreedingLocation;
  mother: null;
  father: null;
  remarks: Array<{ content: string }>;
}

export enum FacilityType {
  Zugerglass = 'zugerglass',
  Hatchery = 'hatchery',
  RoundPool = 'roundpool',
  LongStreamBasin = 'long_stream_basin',
  Stream = 'stream',
  Pond = 'pond',
}

export interface FacilityTypeResponse {
  id: number;
  type: FacilityType;
  sorting: number;
  nameEn: string;
  nameDe: string;
  nameIt: string;
  nameFr: string;
}
